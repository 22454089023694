(function($, window, document,undefined) {

    'use strict';

    var targetNodes         = $("body");
    var MutationObserver    = window.MutationObserver || window.WebKitMutationObserver;
    var myObserver          = new MutationObserver (mutationHandler);
    var obsConfig           = { childList: true, characterData: true, attributes: true, subtree: true };

    //--- Add a target node to the observer. Can only add one node at a time.
    targetNodes.each ( function () {
        myObserver.observe (this, obsConfig);
    } );

    function mutationHandler (mutationRecords) {
       // console.info ("mutationHandler:");

        mutationRecords.forEach ( function (mutation) {
           // console.log (mutation);
            /*
            if (typeof mutation.removedNodes == "object") {
                var jq = $(mutation.removedNodes);
                console.log (jq);
                console.log (jq.is("span.myclass2"));
                console.log (jq.find("span") );
            }*/
            if (typeof mutation.addedNodes == "object") {
                var jq = $(mutation.addedNodes).find('.btn-ripple:not([data-ripple])');
                if(jq.length) {
                    jq.append('<div class="c-ripple js-ripple"><span class="c-ripple__circle"></span></div>');
                    jq.attr("data-ripple","");
                }
            }
        } );
    }



    $("body").on('click.ui.ripple', '.js-ripple', function(e) {

        var $this = $(this);
        var $offset = $this.parent().offset();
        var $circle = $this.find('.c-ripple__circle');

        var x = e.pageX - $offset.left;
        var y = e.pageY - $offset.top;

        $circle.css({
            top: y + 'px',
            left: x + 'px'
        });

        $this.addClass('is-active');

    })
    .on('animationend webkitAnimationEnd oanimationend MSAnimationEnd', '.js-ripple', function(e) {
        $(this).removeClass('is-active');
    });

})(jQuery, window, document);